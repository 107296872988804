import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutWrapper = styled.section`
    width: 100%;
    max-width: 800px;
    margin: 100px auto;

    ${media.lessThan("medium")`
        margin: 40px auto;
    `}
`

export const CourseAboutInfos = styled.section`
    display: flex;
    justify-content: space-between;

    & img {
        flex: 6;
        width: 100%;
    }

    & div {
        flex: 5;

        & h1 {
            color: var(--color-text);
            margin: 20px 0; 
        }

        ${media.lessThan("medium")`
            flex: 1;
            padding: 20px;
        `}
    }


    ${media.lessThan("medium")`
        display: grid;
        justify-content: center;
   `}
`