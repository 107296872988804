import React from "react"

import Layout from '../components/Layout';
import Seo from '../components/seo';

import * as S from "../components/StyledCompanyAbout/styled";

const CompanyAboutPage = () => {
  return (
    <Layout title="Quem somos">
      <Seo title="Quem somos" />
      <S.CourseAboutWrapper>
        <S.CourseAboutInfos>
          <div>
            <h1>Quem Somos</h1>
            <p>
              A DevLoad é uma dos maiores realizadores de sonhos do Mercado
              Digital de Aprendizado e Instrução. Trata-se de uma empresa
              que visa conectar estudantes de desenvolvimento e tudo
              relacionado a tecnologia com escolas renomadas na área, afinal
              estamos vivendo na "era" da tecnologia onde empresas e pessoas
              respiram isso.
            </p>
            <h1>Missão</h1>
            <p>
              Transformar os sonhos de nossos clientes em realidade, fornecendo
              infoprodutos de qualidade, através de cursos profissionalizantes.
            </p>
            <h1>Visão</h1>
            <p>
              Ser a melhor empresa de conexão de alunos com educares e com isso
              proporcionar a mudança de vida no Brasil.
            </p>
          </div>
        </S.CourseAboutInfos>
      </S.CourseAboutWrapper>
    </Layout>
  );
};

export default CompanyAboutPage;